import { ref } from "vue";

import * as Api from "@/api";
import { useStore } from "@/use/useStore";
import { QueryParams } from "@/api/httpClient";

export function useAcsEvents() {
  const { currentUser } = useStore();

  const events = ref<SkudEvent[]>([]);

  async function fetchAcsEvents(params?: QueryParams) {
    const { data } = await Api.fetchEvents(currentUser.value!.id, params);

    events.value = data;
  }

  return {
    events,
    fetchAcsEvents,
  };
}
